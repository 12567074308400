.root {
  padding-top: 40px;
}

.root > .header > * {
  margin-bottom: 15px;
}

.root > .header > p {
  color: #585097;
}

@media (min-width: 640px) {
  .root > .header {
    text-align: center;
    max-width: 425px;
    margin: auto;
  }

  .root > .header > p {
    font-size: 20px;
  }

  .root .section {
    padding: 30px 0;
  }
}
