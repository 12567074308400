.root {
  margin-top: 40px;
}

.root .section {
  padding: 40px 0;
}

.root .section:last-child {
  padding-bottom: 0;
}

.root .section > p {
  margin-bottom: 25px;
}

.root .section > p:last-child {
  margin-bottom: 0;
}

.root .table {
  width: 100%;
  border-collapse: collapse;
  text-align: left;
  margin-bottom: 25px;
}

.root .table th,
.root .table td {
  padding: 5px;
}

.root .table,
.root .table th,
.root .table td {
  border: 1px solid black;
}

@media (max-width: 640px) {
  .root .table {
    table-layout: fixed;
    word-wrap: break-word;
  }
}

@media (min-width: 640px) {
  .root > h1 {
    text-align: center;
  }
}
