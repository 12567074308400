/* Defaults */
.button {
  font-family: 'Lexend Deca', sans-serif;
  display: inline-block;
  background: transparent;
  border-radius: 32px;
  cursor: pointer;
  font-size: 20px;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  padding: 16.5px 30px;
}

.fullWidth {
  display: block;
}

.submitButton {
  border: 0;
  padding: 0;
  margin: 0;
  background: transparent;
}

.short {
  padding: 9px 30px;
  font-size: 16px;
}

.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.32;
}

.defaultLinksDecoration {
  text-decoration: none;
}

.button,
.button:link,
.button:visited,
.button:active,
.button:hover {
  text-decoration: none;
}

.button:hover {
  filter: invert(100%);
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container > svg,
.container > img {
  max-width: 25px;
  max-height: 25px;
}

@media (max-width: 640px) {
  .button {
    font-size: 12px;
    padding: 11.5px 30px;
  }
}

/* Blue color */
.blue {
  border: 1px solid #00C5E0;
}

.blue,
.blue:link,
.blue:visited,
.blue:active {
  color: #201574;
}

.blue:hover {
  border: 1px solid #00C5E0;
  background: #00C5E0;
  color: #fff;
  filter: invert(0);
}

.borderless.blue {
  border: 1px solid #00C5E0;
  background: #00C5E0;
  color: #fff;
}

.borderless.blue:hover {
  border: 1px solid #00C5E0;
  background: #fff;
  color: #201574;
  filter: invert(0);
}

/* Cyan color */
.cyan {
  border: 1px solid #5E7EFF;
}

.cyan,
.cyan:link,
.cyan:visited,
.cyan:active {
  color: #201574;
}

.cyan:hover {
  border: 1px solid #5E7EFF;
  background: #5E7EFF;
  color: #fff;
  filter: invert(0);
}

.borderless.cyan {
  border: 1px solid #5E7EFF;
  background: #5E7EFF;
  color: #fff;
}

.borderless.cyan:hover {
  border: 1px solid #5E7EFF;
  background: #fff;
  color: #201574;
  filter: invert(0);
}

/* Red color */
.red {
  border: 1px solid #BD0000;
}

.red,
.red:link,
.red:visited,
.red:active {
  color: #BD0000;
}

.red:hover {
  border: 1px solid #BD0000;
  background: #BD0000;
  color: #fff;
  filter: invert(0);
}

.red.borderless {
  border: 1px solid #BD0000;
  background: #BD0000;
  color: #fff;
}

.red.borderless:hover {
  border: 1px solid #BD0000;
  background: #fff;
  color: #BD0000;
  filter: invert(0);
}

/* White color */
.white {
  border: 1px solid #201574;
}

.white,
.white:link,
.white:visited,
.white:active {
  color: #201574;
}

.white:hover {
  border: 1px solid #201574;
  background: #201574;
  color: #fff;
  filter: invert(0);
}

/* Yellow color */
.yellow {
  border: 1px solid #fff4a7;
}

.yellow,
.yellow:link,
.yellow:visited,
.yellow:active {
  background: #fff4a7;
  color: #201574;
}

.yellow:hover {
  border: 1px solid #fff;
  background: #fff;
  color: #201574;
  filter: invert(0);
}

/* Transparent color */
.transparent {
  font-family: 'Mulish', sans-serif;
  padding: 0;
  font-size: 16px;
}

.transparent,
.transparent:link,
.transparent:visited,
.transparent:active {
  background: transparent;
  color: #201574;
}

.transparent:hover {
  color: #5E7EFF;
  filter: invert(0);
}
