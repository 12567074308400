.root {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.children > :first-child {
  margin-right: 15px;
}

.root svg {
  min-width: 20px;
  min-height: 20px;
}

.error svg rect {
  stroke: #BD0000;
}

@media (max-width: 640px) {
  .root {
    font-size: 12px;
  }
}
