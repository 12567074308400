.root .error {
  max-width: 750px;
  color: #D94B2F;
  margin: 0 auto 25px auto;
}

.root .form {
  display: flex;
  flex-direction: column;
}

.root .form > :nth-child(1) {
  margin-bottom: 15px;
}

.root .form > :nth-child(1) input {
  background: #201574;
  border-radius: 4px;
  border: 1px solid #fff;
  height: 40px;
}

.root .form > :nth-child(1) input,
.root .form > :nth-child(1) input::placeholder {
  color: #fff;
}

.root .form .button {
  border-radius: 4px;
}

@media (min-width: 768px) {
  .root .form {
    flex-direction: row;
  }

  .root .form > :nth-child(1) {
    flex-grow: 1;
    margin-right: 15px;
    margin-bottom: 0;
  }

  .root .form > :nth-child(1) input {
    height: 50px;
  }
  
  .root .form .button {
    font-size: 16px;
    padding: 14px 30px
  }
}