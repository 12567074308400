/** Form Styles **/
.form > *,
.form .inputs > * {
  margin-bottom: 25px;
}

.form > :nth-child(1),
.form > :nth-child(3) {
  margin-bottom: 50px;
}

.form > :nth-child(2),
.form > :last-child {
  margin-bottom: 0;
}

.form > :last-child div[class*="Button_blue"] {
  display: block;
}

@media (min-width: 640px) {
  .form {
    text-align: center;
  }

  .form > :first-child {
    margin-bottom: 50px;
  }

  .form .inputs {
    text-align: left;
    max-width: 400px;
    margin: 0 auto 25px auto;
  }

  .form > :last-child div[class*="Button_blue"] {
    display: inline-block;
  }
}

/* Messages Styles **/
.root {
  padding-top: 40px;
}

.root .messages {
  padding: 0 0 150px 0;
}

.root .messages > * {
  margin-bottom: 25px;
}

.root .messages > :last-child {
  margin-bottom: 0;
}

@media (min-width: 640px) {
  .root .messages {
    max-width: 750px;
    margin: 0 auto;
    padding: 150px 0;
    text-align: center;
  }
}

