
.root {
  overflow: hidden;
  white-space: nowrap;
  height: 25px;
}

.root span {
  display: inline-block;
}

.root span:nth-of-type(1) {
  animation: markee 10s linear infinite;
}

.root span:nth-of-type(2) {
  animation: markee2 10s linear infinite;
  animation-delay: 5s;
}

@keyframes markee {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

@keyframes markee2 {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-200%);
  }
}