/** Section 1 **/
.root .section1 {
  padding: 40px 25px;
}

.root .section1 > h1:nth-child(1) {
  color: #201574;
  margin-bottom: 50px;
}

.root .section1 span.hi {
  display: none;
}

@media (min-width: 640px) {
  .root .section1 {
    text-align: center;
  }

  .root .section1 span.hi {
    display: inline-block;
    margin-right: 25px;
  }
}

/** Section 2 **/
.root .section2 {
  align-items: center;
  padding: 0 25px;
}

.root .section2 .content {
  padding-bottom: 50px;
}

.root .section2 .content .text > * {
  margin-bottom: 25px;
}

.root .section2 .content .text > *:last-child {
  margin-bottom: 0;
}

.root .section2 .image img {
  width: 100%;
  border-radius: 20px;
}


@media (min-width: 640px) {
  .root .section2 {
    display: flex;
    justify-content: space-between;
    height: 450px;
  }

  .root .section2 .content {
    padding-bottom: 0;
  }  

  .root .section2 > * {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% / 2 - 25px);
  }

  .root .section2 .image img {
    max-height: 400px;
  }

  .root .section2 .content .text {
    max-width: 400px;
  }
}

/** Section 5 **/
.root .section5 {
  text-align: center;
  padding: 70px 25px 0 25px;
}

.root .section5 .content {
  max-width: 950px;
  margin: 0 auto;
}

.root .section5 .content p {
  font-size: 14px;
  line-height: 21px;
  color: #201574;
}

.root .section5 .content > svg {
  margin-top: 25px;
}

@media (min-width: 640px) {
  .root .section5 .content p {
    font-size: 23px;
    line-height: 35px;
  }
}

/** Section 6 **/
.root .section6 {
  padding: 100px 0 0 0;
}

.root .section6 > :nth-child(1) {
  background-color: #F6F1EE;
  padding: 40px 25px;
}

.root .section6 > :nth-child(1) > h1 {
  margin-bottom: 50px;
}

.root .section6 > :nth-child(2) {
  text-align: center;
}

.root .section6 > :nth-child(1) .content > * {
  margin-bottom: 25px;
}

.root .section6 > :nth-child(1) .content > *:last-child {
  margin-bottom: 0;
}

@media (min-width: 1024px) {
  .root .section6 {
    display: flex;
  }

  .root .section6 > :nth-child(1) {
    position: relative;
    padding: 80px 25px;
    border-radius: 20px;
  }

  .root .section6 > :nth-child(1) > * {
    max-width: 700px;
    margin: 0 auto;
  }

  .root .section6 > :nth-child(1) .content {
    display: flex;
    justify-content: space-between;
  }

  .root .section6 > :nth-child(1) .content > * {
    width: calc(100% / 2 - 25px);
  }

  .root .section6 > :nth-child(1) {
    width: 100%;
  }

  .root .section6 > :nth-child(2) {
    width: 0%;
  }

  .root .section6 > :nth-child(2) .image svg {
    max-width: 140px;
    max-height: 140px;
  }
}

/** Section 7 **/
.root .section7 {
  padding: 40px 25px 0 40px;
}

.root .section7 .buttons {
  width: 100%;
}

.root .section7 .buttons div[class*="Button_blue"] {
  margin-bottom: 10px;
}

.root .section7 .buttons div[class*="Button_blue"],
.root .section7 .buttons div[class*="Button_cyan"] {
  display: block;
}

@media (min-width: 640px) {
  .root .section7 {
    padding: 80px 25px 0 80px;
  }

  .root .section7 .buttons {
    display: flex;
    justify-content: center;
  }

  .root .section7 .buttons > :nth-child(1) {
    margin-bottom: 0;
    margin-right: 10px;
  }

  .root .section7 .buttons div[class*="Button_blue"],
  .root .section7 .buttons div[class*="Button_cyan"] {
    display: inline-block;
  }  
}

/** Section 8 **/
.root .section8 {
  padding: 100px 0 0 0;
  color: #fff;
}

.root .section8 > :nth-child(1) {
  background-color: #201574;
  padding: 40px 25px;
}

.root .section8 > :nth-child(1) > h1 {
  margin-bottom: 50px;
}

.root .section8 > :nth-child(2) {
  text-align: center;
}

.root .section8 > :nth-child(1) .content > * {
  margin-bottom: 25px;
}

.root .section8 > :nth-child(1) .content > *:last-child {
  margin-bottom: 0;
}

@media (min-width: 1024px) {
  .root .section8 {
    display: flex;
  }

  .root .section8 > :nth-child(1) {
    position: relative;
    padding: 80px 25px;
    border-radius: 20px;
  }

  .root .section8 > :nth-child(1) > * {
    max-width: 700px;
    margin: 0 auto;
  }

  .root .section8 > :nth-child(1) .content {
    display: flex;
    justify-content: space-between;
  }

  .root .section8 > :nth-child(1) .content > * {
    width: calc(100% / 2 - 25px);
  }

  .root .section8 > :nth-child(1) {
    width: 100%;
  }

  .root .section8 > :nth-child(2) {
    width: 0%;
  }

  .root .section8 > :nth-child(2) .image svg {
    max-width: 140px;
    max-height: 140px;
  }
}
