.root div[class*="Button_button"] svg {
  min-width: 56px;
  min-height: 56px;
  margin-right: 0;
}

.root iframe {
  width: 100%;
  min-height: 200px;
}

@media (min-width: 640px) {
  .root iframe {
    min-height: 600px;
  }
}
