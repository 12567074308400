.root {
  cursor: pointer;
}

.root .header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.root .header > svg {
  margin-left: 25px;
  min-width: 15px;
  max-width: 15px;
}

.root .content {
  width: 95%;
  color: #585097;
}

.root .content > * {
  margin-bottom: 20px;
}

@media (min-width: 640px) {
  .root .header {
    align-items: center;
  }

  .root .header > svg {
    min-width: 25px;
    max-width: 25px;
  }

  .root .content {
    width: 80%;
  }

  .root .content p {
    font-size: 20px;
  }
}

