.root .halfWidth > *,
.root .marginBottom {
  margin-bottom: 25px;
}

.root .buttons {
  display: flex;
  justify-content: center;
}

@media (min-width: 640px) {
  .root .halfWidth {
    display: flex;
    justify-content: space-between;
  }

  .root .halfWidth > * {
    width: calc(100% / 2 - 15px);
  }
}

