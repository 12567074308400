.root {
  position: relative;
  padding-bottom: 40px;
}

.root > h1 {
  padding-bottom: 40px;
}

.root svg {
  cursor: pointer;
}

.root svg.left {
  position: absolute;
  bottom: 0;
  left: 0;
}

.root svg.right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.root .container .testimonial {
  position: relative;
  padding-bottom: 50px;
}

.root .container .testimonial > p:nth-last-child(1) {
  position: absolute;
  bottom: 0;
}

@media (min-width: 768px) {
  .root {
    padding: 0 30px;
  }

  .root .container {
    display: flex;
    justify-content: center;
  }

  .root .container > * {
    width: calc(100% / 3);
    margin-right: 25px;
  }

  .root .container > :last-child {
    margin-right: 0;
  }

  .root svg.left {
    bottom: 50%;
  }
  
  .root svg.right {
    bottom: 50%;
  }

  .center {
    text-align: center;
  }

  .center .testimonial > p:nth-last-child(1) {
    left: calc(50% - 30px);
  }
}