.root {
  position: relative;
}

.root .counter {
  position: absolute;
  top: 2px;
  right: -2px;
  background: #00C5E0;
  border-radius: 50%;
  color: #fff;
  font-size: 7px;
  width: 14px;
  height: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}