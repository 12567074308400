.content {
  padding: 25px 25px;
}

.content .logoutContainer {
  margin-top: 25px;
}

@media (min-width: 640px) { 
  .content {
      padding: 25px 0;
  }
}

.login {
  display: flex;
  justify-content: center;
  padding-top: 50px;
}

.login .amplify {
  --amplify-primary-color: #201574;
  --amplify-primary-tint: #201574;
  --amplify-primary-shade: #201574;
  --box-shadow: none;
  --container-justify: initial;
  --container-align: initial;
  --container-height: 0;
  --padding: 0;
}

/*
 * Hide header/footer components
 */
.root div[id="navbar"] > :nth-child(1),
.root div[id="navbar"] > :nth-child(3) {
  visibility: hidden;
}

.root div[id="navbar"] > :nth-child(2) a {
  pointer-events: none;
  cursor: default;
}

.root div[id="footer"] div[class*="Footer_top"] > :nth-child(2),
.root div[id="footer"] div[class*="Footer_top"] > :nth-child(3),
.root div[id="footer"] div[class*="Footer_top"] > :nth-child(5) {
  visibility: hidden;
}

.root div[id="footer"] div[class*="Footer_bottom"] > :nth-child(2) > :nth-child(4),
.root div[id="footer"] div[class*="Footer_bottom"] > :nth-child(2) > :nth-child(5) {
  visibility: hidden;
}

.root div[id="footer"] div[class*="Footer_bottom"] > :nth-child(3) {
  visibility: hidden;
}
