@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca&family=Mulish&display=swap');

body,
html,
#root {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Mulish', sans-serif;
  font-variant-ligatures: no-common-ligatures;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: normal;
  color: #201574;
}

hr {
  border: 0;
  border-bottom: 1px solid #DDDDDD;
  margin: 30px 0;
  padding: 0;
}

input, select, textarea, button{
  font-family: inherit;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Lexend Deca', sans-serif;
  font-weight: normal;
}

h1 {
  font-size: 40px;
  line-height: 50px;
}

h2 {
  font-size: 28px;
  line-height: 43px;
}

h3 {
  font-size: 24px;
  line-height: 43px;
}

h4 {
  font-size: 20px;
  line-height: 43px;
}

h5 {
  font-size: 18px;
  line-height: 43px;
}

h6 {
  font-size: 16px;
  line-height: 43px;
}

@media (max-width: 640px) {
  h1 {
    font-size: 28px;
    line-height: 28px;
  }

  h2 {
    font-size: 24px;
    line-height: 28px;
  }

  h3 {
    font-size: 20px;
    line-height: 20px;
  }
  
  h4 {
    font-size: 18px;
    line-height: 20px;
  }
  
  h5 {
    font-size: 16px;
    line-height: 20px;
  }

  label {
    font-size: 12px;
  }
}
