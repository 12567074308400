.root > h1 {
  text-align: center;
  margin-bottom: 25px;
}

.root .subscription {
  text-align: center;
}

.root .subscription > * {
  margin-bottom: 25px;
}

.root .subscription .paymentFailed {
  border: 1px solid #BD0000;
  border-radius: 7px;
  background: rgba(189, 0, 0, 0.08);
  color: #BD0000;
  margin-left: auto;
  margin-right: auto;
  padding: 25px;
}

@media (max-width: 640px) {
  .root > h1 {
    text-align: unset;
  }

  .root .subscription {
    text-align: initial;
  }
}
