.root {
  padding-top: 70px;
}

/** Header **/
.root .header {
  text-align: left;
}

.root .header > h1 {
  margin-top: 50px;
}

@media (min-width: 640px) {
  .root .header {
    text-align: center;
  }
}

@media (max-width: 1200px) {
  .root {
    padding: 40px 20px 20px 20px;
  }
}

/* Step 4 */
.root .content .step4 {
  padding-top: 30px;
  text-align: left;
}

.root .content .step4 > * {
  padding-top: 25px;
}

.root .content .step4 > :nth-last-child(2) {
  padding-bottom: 50px;
}

.root .content .step4 > :first-child {
  padding-top: 0;
}

@media (min-width: 640px) {
  .root .content .step4 {
    text-align: center;
  }

  .root .content .step4 .button {
    max-width: 300px;
    margin: auto;
  }
}
