.root {
  z-index: 100;
  width: 100%;
  position: fixed;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
}

.root > .container {
  display: flex;
  align-items: center;
  padding: 20px 30px;
}

.root .container > :nth-child(1) {
  flex-grow: 1;
  padding-right: 25px;
}

.root .container > :nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.root .container > :nth-child(2) > * {
  margin-right: 25px;
}

.root .container > :nth-child(2) > :last-child {
  margin-right: 0;
}

.root .container > :nth-child(2) div[class*="Button_blue"] {
  min-width: 60px;
  padding: 7px 15px;
}

@media (max-width: 640px) {
  .root {
    font-size: 11px;
    line-height: 17px;
  }
}

