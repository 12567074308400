.lightBlue {
  color: #585097;
}

.root {
  position: relative;
  border: 1px solid #DDDDDD;
  border-radius: 14px;
}

.root > h6.header {
  position: absolute;
  top: -22px;
  left: 50%;
  transform: translateX(-50%);
  color: #5E7EFF;
  font-size: 12px;
  background: #fff;
  padding: 0 10px;
}

.root.active {
  border-color: #5E7EFF;
}

.root .container {
  padding: 30px;
}

.root .container > h3 {
  line-height: unset;
}

.root .container > :nth-child(2) {
  margin-top: 10px;
}

.root .container > :nth-child(3) {
  margin-top: 20px;
}