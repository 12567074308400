.root {
  text-align: center;
  padding: 80px 0;
}

.lightBlue {
  color: #585097;
}

.root > * {
  margin-bottom: 25px;
}

.root > h1 {
  margin-bottom: 25px;
}

.root > :last-child {
  margin-bottom: 0;
}

.root .buttons > :first-child {
  margin-bottom: 15px;
}

.root .buttons .button {
  display: block;
  font-size: 16px;
}

.root .buttons div[class*="Button_white"] svg path {
  fill: #201574;
}

.root .buttons div[class*="Button_white"]:hover svg path {
  fill: #fff;
}

@media (min-width: 640px) {
  .root > * {
    margin-bottom: 50px;
  }

  .root > p {
    font-size: 20px;
  }

  .root .buttons {
    display: flex;
    justify-content: center;
  }

  .root .buttons > :first-child {
    margin-bottom: 0;
    margin-right: 25px;
  }

  .root .buttons .button {
    font-size: 20px;
    display: inline-block;
  }
}
