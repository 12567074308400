.lightBlue {
  color: #585097;
}

/** Total **/
.subTotal {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
}

.subTotal > :nth-child(1) {
  display: flex;
  align-items: center;
}

.subTotal > :nth-child(1) > :nth-child(1) {
  margin-right: 30px;
}

/** Checkout **/
.checkout {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.checkout > :nth-child(2) {
  width: 30%;
  margin-left: 20px;
}


.checkout > :nth-child(1) > * {
  margin-bottom: 20px;
}

.checkout > :nth-child(1) > :last-child {
  margin-bottom: 0;
}

@media (max-width: 640px) {
  /** Total **/
  .subTotal > :nth-child(1) {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .subTotal > :nth-child(1) > :nth-child(1) {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .subTotal > :nth-child(1) > :nth-child(2) {
    font-size: 12px;
  }

  /** Checkout **/
  .checkout > :nth-child(2) {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }

  .checkout {
    flex-direction: column;
    align-items: flex-start;
  }
}
