.root {
  padding: 0 !important;
  width: 40px;
  height: 24px;
}

.root .hamburgerInner,
.root .hamburgerInner:after,
.root .hamburgerInner:before {
  width: 28px !important;
  height: 2px !important;
}

.blue > div > div,
.blue > div > div:after,
.blue > div > div:before {
  background-color: #201574 !important;
}

.white > div > div,
.white > div > div:after,
.white > div > div:before {
  background-color: #fff !important;
}

@media (min-width: 640px) {
  .root .hamburgerInner,
  .root .hamburgerInner:after,
  .root .hamburgerInner:before {
    width: 40px !important;
    height: 3px !important;
  }
}