.root .halfWidth > *,
.root .marginBottom {
  margin-bottom: 25px;
}

.root .buttons {
  display: flex;
  flex-direction: column-reverse;
}

.root .buttons > * {
  margin-bottom: 10px;
}

@media (min-width: 640px) {
  .root .halfWidth {
    display: flex;
    justify-content: space-between;
  }

  .root .halfWidth > * {
    width: calc(100% / 2 - 15px);
  }

  .root .buttons {
    flex-direction: row;
    justify-content: space-between;
  }

  .root .buttons > * {
    margin-bottom: 0;
    width: calc(100% / 2 - 15px);
  }
}
