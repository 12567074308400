.root .container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.root .container .left,
.root .container .right {
  position: absolute;
  font-weight: bold;
  font-size: 25px;
}

.root .container .left {
  left: 0;
}

.root .container .right {
  right: 0;
}