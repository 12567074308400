.lightBlue {
  color: #585097;
}

.root,
.root .item .title {
  display: flex;
  justify-content: space-between;
}

.root > * {
  width: calc(100% / 2 - 15px);
}

.root .item {
  border: 1px solid #DDDDDD;
  border-radius: 10px;
  padding: 15px 20px;
  margin-bottom: 15px;
  cursor: pointer;
}

@media (max-width: 640px) {
  .root {
    flex-direction: column;
  }

  .root > * {
    width: 100%;
  }

  .root .item {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-radius: 0;
    padding: 15px 0;
  }
}

@media (min-width: 640px) {
  .root .item > p {
    font-size: 20px;
  }
}
