.root {
  padding: 40px 25px 0 25px;
}

.lightBlue {
  color: #585097;
}

.root .header > * {
  margin-bottom: 25px;
}

.root hr {
  margin: 50px 0;
}

/** Starter pack section */
.root .starterPack {
  border: 1px solid #5E7EFF;
  border-radius: 13px;
  padding: 25px;
  margin-top: 30px;
}

.root .starterPack .row {
  display: flex;
  justify-content: space-between;
}

.root .starterPack .row .button {
  margin-top: 20px;
  font-size: 16px;
}

.root .starterPack .row .button svg {
  margin-left: 5px;
}

.root .starterPack .row .button:hover svg polyline {
  stroke: #201574;
}

.root .starterPack > :nth-child(1) > :nth-child(1) {
  width: 70%;
}

.root .starterPack > :nth-child(1) > :nth-child(2) {
  width: 30%;
}

.root .starterPack > :nth-child(1) > :nth-child(2) {
  text-align: right;
}

.root .starterPack > :nth-child(2) {
  display: flex;
  align-items: flex-end;
  margin-top: 20px;
}

.root .starterPack > :nth-child(2) > :nth-child(1) {
  width: 100%;
}

/** Save money section */
.root .saveMoney .table {
  margin-top: 25px;
  border: 1px solid black;
  border-radius: 12px;
}

.root .saveMoney .table .row {
  display: flex;
  border-bottom: 1px solid #DDDDDD;
}

.root .saveMoney .table h4 {
  line-height: initial;
}

.root .saveMoney .table span.red {
  color: #FF6C88;
  margin-left: 15px;
}

.root .saveMoney .table span.lineThrough {
  text-decoration: line-through;
}

.root .saveMoney .table .row:last-child {
  border-bottom: 0;
}

.root .saveMoney .table .row > * {
  width: 50%;
  padding: 12px 15px;
}

.root .saveMoney .table .row > :last-child {
  border-bottom: 0;
}

.root .saveMoney .table .row > :nth-child(1) {
  border-right: 1px solid #DDDDDD;
}

/** Faqs section */
.root .faqs .faqsHeader > p {
  margin-top: 15px;
}

.root .faqs .button {
  max-width: 300px;
  margin: auto;
}

@media (min-width: 640px) {
  .root {
    padding: 40px 0 0 0;
  }

  .root hr {
    margin: 50px 0;
  }

  .root .header {
    text-align: center;
    max-width: 550px;
    margin: auto;
  }

  .root .header p {
    font-size: 20px;
  }

  /** Starter pack section */
  .root .starterPack {
    max-width: 500px;
    margin-top: 75px;
    margin-left: auto;
    margin-right: auto;
  }

  .root .starterPack .row .button {
    margin-top: 0;
  }

  .root .starterPack > :nth-child(2) > :nth-child(1),
  .root .starterPack > :nth-child(2) > :nth-child(2) {
    width: calc(100% /2 - 25px);
  }

  /** Save money section */
  .root .saveMoney {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .root .saveMoney .table {
    margin-top: 0;
    max-width: 500px;
  }

  .root .saveMoney > * {
    width: calc(100% / 2 - 50px);
  }

  .root .saveMoney .table p {
    font-size: 20px;
  }

  /** Faqs section */
  .root .faqs .faqsHeader {
    text-align: center;
  }

  .root .faqs .faqsHeader > p {
    font-size: 20px;
  }
}
