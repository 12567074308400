.root {
  display: flex;
  justify-content: space-between;
  border: 1px solid #DDDDDD;
  border-left: 0;
  border-right: 0;
  font-family: 'Lexend Deca', sans-serif;
  font-size: 12px;
  line-height: 13px;
}

.root .tab {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 0;
  cursor: pointer;
}

.root .tab .container {
  width: 100%;
  text-align: center;
  padding: 10.5px 10px;
  border-left: 1px solid #DDDDDD;
}

.root > .tab:last-child .container {
  border-right: 1px solid #DDDDDD;
}

.root .tab.active {
  border-bottom: 2px solid #201574;
}