.root {
  padding-bottom: 80px;
}

.root .content {
  text-align: center;
  margin-bottom: 50px;
}

.root .content > h1:nth-child(1) {
  color: #F6F1EE;
  font-size: 80px;
  line-height: 80px;
  z-index: 1;
}

.root .content > h1:nth-child(2) {
  color: #201574;
  z-index: 2;
}

.root .buttons div[class*="Button_blue"] {
  display: block;
  z-index: 100;
}

@media (min-width: 768px) {
  .root .content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 370px;
    position: relative;
    overflow: hidden; /* Hide h1 overflow **/
  }

  .root .content > h1:nth-child(1) {
    position: absolute;
    font-size: 370px;
    line-height: 370px;
  }

  .root .buttons div[class*="Button_blue"] {
    display: inline-block;
  }

  .root .buttons {
    text-align: center;
  }
}