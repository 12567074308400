.root {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  font-size: 20px;
}

.lightBlue {
  color: #585097;
}

.red {
  color: #FF6C88;
}

.root > :nth-child(2) {
  width: 30%;
  margin-left: 20px;
}

.root .items {
  display: flex;
  margin-top: 20px;
}

.root .items > :nth-child(2) {
  margin-left: 35px;
}

.root .items img {
  width: 150px;
  height: 150px;
  border-radius: 14px;
}

.root .items > :nth-child(2) > :nth-child(3) ,
.root .items > :nth-child(2) > :nth-child(5),
.root .items  > :nth-child(2) > :last-child {
  margin-top: 10px;
}

.root .quantity {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.root .quantity .quantitySelector {
  margin-bottom: 20px;
}

.root .quantity .quantitySelector select {
  font-family: 'Lexend Deca', sans-serif;
  border-radius: 32px;
  padding: 0 25px;
}

.root .quantity .quantitySelector select,
.root .quantity .quantitySelector select::placeholder {
  font-size: 20px;
}

.root .quantity .quantityRemove .container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.root .quantity .quantityRemove .container svg {
  width: 16px;
  height: 16px;
}

.root .quantity .quantityRemove:hover .container svg line {
  stroke: #fff;
}

@media (max-width: 640px) {
  .root {
    flex-direction: column;
  }

  .root > :nth-child(2) {
    width: 100%;
    margin-left: 0;
  }

  .root .items {
    font-size: 12px;
  }

  .root .items img {
    width: 100px;
    height: 100px;
  }

  .root > :nth-child(2) > h3,
  .root .items > :nth-child(2) h3 {
    font-size: 16px;
  }

  .root > :nth-child(2) h5.red {
    font-size: 13px;
  }

  .root .quantity {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .root .quantity .quantitySelector {
    margin-bottom: 0;
    margin-right: 15px;
    flex-grow: 1;
  }

  .root .quantity .quantitySelector select,
  .root .quantity .quantitySelector select::placeholder {
    font-size: 12px;
  }

  .root > :nth-child(2) > :last-child h5 {
    margin-top: 25px;
  }
}
