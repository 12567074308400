
.root .container {
  padding-top: 50px;
}

.root .container > * {
  margin-bottom: 50px;
}

@media (min-width: 640px) {
  .root > .header {
    text-align: center;
    max-width: 60%;
    margin: auto;
    font-size: 20px;
  }

  .root .container > :nth-child(1) {
    width: calc(100% / 3);
    margin-left: auto;
    margin-right: auto;
  }

  .root .button {
    max-width: 300px;
    margin: auto;
  }
}
