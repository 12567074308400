.root {
  display: flex;
  align-items: center;
  width: 100%;
  height: 70px;
  position: fixed;
  top: 0;
  color: #201574;
  background: #fff;
  z-index: 99;
  border-bottom: 1px solid #201574;
}

.left, .center, .right, .right .actions {
  display: flex;
  align-items: center;
}

.left {
  margin-left: 20px;
  width: 40%;
  justify-content: flex-start;
}

.center {
  flex-grow: 1;
  justify-content: center;
}

.center svg {
  max-width: 30px !important;
  max-height: 30px !important;
  margin-top: 5px;
}

.center svg path {
  fill: #201574;
}

.right {
  margin-right: 20px;
  width: 40%;
  justify-content: flex-end;
}

.right .actions > * {
  margin-left: 15px;
}

.right .actions svg {
  max-width: 25px !important;
  max-height: 25px !important;
}

@media (min-width: 640px) {
  .root {
    height: 90px;
  }

  .left {
    margin-left: 30px;
  }

  .center svg {
    max-width: 38px !important;
    max-height: 38px !important;
  }

  .right {
    margin-right: 30px;
  }

  .right .button {
    padding: 10px 30px;
    font-size: 14px;
  }
}

/** Navbar **/
.navbarWhite {
  color: #fff;
  background: transparent;
  border: 0;
}

.navbarWhite div[class*="Button_transparent"] {
  color: #fff;
}

.navbarWhite > :nth-child(2) svg path {
  fill: #fff;
}

.navbarWhite > :nth-child(3) svg g {
  stroke: #fff;
}

.navbarWhite > :nth-child(3) div[class*="Button_blue"] {
  background: #fff;
  color: #201574;
  border: solid 1.5px #fff;
}

.navbarWhite > :nth-child(3) div[class*="Button_blue"]:hover {
  background: #201574;
  color: #fff;
  border: solid 1.5px #201574;
}


/** Full menu popup **/
.menuPopup {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #201574;
  color: #fff;
  width: 100%;
  height: 100vh;
  padding: 70px 0 0 0;
  margin: 0;
  z-index: 98;
}

.menuPopup .container {
  padding: 25px 25px 0 25px;
}

.menuPopup .container .navigation .button {
  color: #fff;
  display: block;
  font-size: 23px;
}

.menuPopup .container .navigation .button div[class*="Button_container"] {
  justify-content: flex-start;
}

.menuPopup .container .navigation .button:hover {
  color: #F6F1EE;
  filter: invert(0);
}

.menuPopup .container .social {
  margin-top: 25px;
}

.menuPopup .container .social svg {
  width: 22px;
  height: 22px;
  margin-right: 15px;
}

.menuPopup .container .social .button:hover {
  filter: invert(0);
}

.menuPopup .container .social .button:hover svg path {
  fill: #F6F1EE;
}

@media (min-width: 640px) {
  .menuPopup {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menuPopup .container {
    margin-top: -140px;
    text-align: center;
  }

  .menuPopup .container .navigation .button div[class*="Button_container"] {
    justify-content: center;
  }

  .menuPopup .container .navigation .button {
    font-size: 40px;
  }
}
