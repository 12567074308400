/** Global styles **/
.spaceBetween > * {
  margin-bottom: 25px;
}

.spaceBetween > :last-child {
  margin-bottom: 0;
}

.sectionHeader {
  border-bottom: 1px solid #201574 !important;
}

.sectionHeader * {
  color: #000 !important;
}

.textCenter {
  text-align: center;
}

/** Account styles **/
.account .dashboard {
  padding: 25px;
}

.account .dashboard .title > :nth-child(1) {
  margin-bottom: 25px;
}

.account .logoutButton {
  color: #BD0000;
  font-size: 12px;
  font-family: 'Lexend Deca', sans-serif;
}

.account .dashboard .menuDesktop {
  display: flex;
  justify-content: space-between;
  margin: 70px 0 50px 0;
}

.account .dashboard .menuDesktop > * {
  border: 1px solid #979797;
  border-radius: 16px;
  width: calc(100% / 3 - 25px);
  cursor: pointer;
}

.account .dashboard .menuDesktop .container {
  padding: 35px;
  text-align: center;
}

.account .dashboard .menuDesktop .container svg {
  height: 100px;
}

.account .dashboard .menuMobile {
  margin: 25px 0;
}

.account .dashboard .menuMobile > * {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #DDDDDD;
  padding: 15px 0;
  cursor: pointer;
}

.account .dashboard .menuMobile > :first-child {
  border-top: 1px solid #DDDDDD;
}

.account .dashboard .menuMobile svg {
  width: 30px;
}

.account .content .container {
  padding: 25px 25px;
}

@media (min-width: 640px) {
  .account .dashboard {
    padding: 40px 0 0 0;
  }

  .account .dashboard .title {
    text-align: center;
  }

  .account .dashboard .buttons {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: auto;
  }

  .account .dashboard .buttons > * {
    width: calc(100% / 2 - 15px);
  }

  .account .content .container {
    padding: 25px 0;
  }
}

/** Login styles **/
.login {
  padding-top: 40px;
}

.login > * {
  margin-bottom: 25px;
}

.login > :first-child {
  margin-bottom: 50px;
}

.login > :last-child {
  margin-top: 25px;
  margin-bottom: 0;
}

.login > :last-child div[class*="Button_blue"] {
  display: block;
}

@media (min-width: 640px) {
  .login {
    max-width: 400px;
    margin: 0 auto;
  }

  .login > :first-child {
    margin-bottom: 50px;
  }

  .login > :first-child,
  .login > :last-child {
    text-align: center;
  }

  .login > :last-child div[class*="Button_blue"] {
    display: inline-block;
  }
}
