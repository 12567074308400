.root {
  font-size: 16px;
}

.root.error {
  color: #BD0000;
}

.root > *,
.root .buttonContainer > input,
.root .buttonContainer > textarea {
  width: 100%;
}

/** Fix for wrong width using 100% and padding **/
.root .buttonContainer > input,
.root .buttonContainer > textarea {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.root .label {
  margin-bottom: 5px;
}

.root.error .errorText {
  margin-top: 5px;
}

.root input,
.root input::placeholder,
.root textarea,
.root textarea::placeholder {
  font-size: 16px;
}

.root input,
.root textarea {
  padding: 19px 15px;
  border: 0;
  border: 1px solid #201574;
  border-radius: 4px;
}

.root input:focus,
.root textarea:focus {
  border: 1px solid #201574;
  outline: none;
}

.root.error input,
.root.error input:focus,
.root.error textarea,
.root.error textarea:focus {
  border: 1px solid #BD0000;
}

.root input::placeholder,
.root textarea::placeholder {
  color: #999999;
}

.root .buttonContainer {
  position: relative;
}

.root .buttonContainer img,
.root .buttonContainer svg {
  position: absolute;
  width: 22px;
  right: 20px;
  top: 20px;
}

.root .buttonContainer .tooltipText {
  position: absolute;
  z-index: 1;
  width: 150px;
  background-color: #EEEEEE;
  color: #201574;
  box-shadow: 5px 5px 10px #ccc;
  font-size: 12px;
  line-height: 15px;
  padding: 10px;
  top: 55px;
  right: 0px;
}

.root .buttonContainer .tooltipText::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 82%;
  margin-left: -10px;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #EEEEEE transparent;
}

@media (max-width: 640px) {
  .root {
    font-size: 12px;
  }

  .root input,
  .root textarea {
    padding: 9px 15px;
  }

  .root .buttonContainer img,
  .root .buttonContainer svg {
    right: 15px;
    top: 10px;
  }
}

/** Stripe inputs **/
.stripeInputComponent {
  border: 1px solid #201574;
  border-radius: 4px;
  font-size: 16px;
  padding: 20.5px 15px;
  color: #808080;
  margin-top: 5px;
}

@media (max-width: 640px) {
  .stripeInputComponent {
    padding: 12px 15px;
  }
}
