.textCenter {
    text-align: center;
}

.root .navbar {
    margin-bottom: 20px;
}

.root .container {
    display: grid;
    gap: 20px;
}

.root .container .user {
    border: 1px solid #999999;
    border-radius: 10px;
    padding: 25px;
}

.root .container .user.underline {
    text-decoration: underline;
}

.root .pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

@media (min-width: 640px) { 
    .root .container {
        grid-template-columns: 1fr 1fr 1fr;
    }  
}