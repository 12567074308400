.root .badge {
  display: block;
  padding: 35px 25px;
  border: 1px solid #5E7EFF;
  border-radius: 16px;
  text-align: center;
  margin: 25px 0;
}

.root .red {
  color: #FF6C88;
}

.root .pack .container {
  font-size: 16px;
  text-align: center;
  color: #585097;
}

.root .pack .container > * {
  margin-bottom: 30px;
}

.root .subscription .container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
}

.root .subscription .container > * {
  width: 100%;
  margin-bottom: 20px;
}

.root .subscription .container > :last-child {
  margin-bottom: 0;
}

.root .subscription .container .button h4 {
  line-height: unset;
}

.root .subscription .container .button svg {
  min-width: 8px;
  max-width: 8px;
  margin-left: 10px;
}

.root .subscription .container .button p {
  margin-top: 10px;
}

.root .subscription .container .button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border: 1px solid #999999;
  border-radius: 10px;
  padding: 25px;
  height: 75px;
}

.root .subscription .cancelPopup {
  text-align: center;
}

.root .subscription .cancelPopup > p {
  margin-bottom: 25px;
}

.root .subscription .cancelPopup > :last-child {
  margin-top: 15px;
}

@media (min-width: 640px) {
  .root .pack .container {
    font-size: 20px;
  }

  .root .pack .container {
    width: 60%;
    margin: auto;
  }

  .root .pack .container .button {
    max-width: 300px;
    margin: auto;
  }

  .root .subscription .container .button svg {
    min-width: 12px;
    max-width: 12px;
  }

  .root .subscription .container {
    flex-direction: row;
  }

  .root .subscription .container > * {
    width: calc(100% / 2 - 10px);
  }

  .root .subscription .cancelPopup > p {
    font-size: 20px;
  }
}