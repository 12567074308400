.lightBlue {
  color: #585097;
}

.root > h1 {
  text-align: center;
  margin-bottom: 25px;
}

.root .table,
.root .table .item .title {
  display: flex;
  justify-content: space-between;
}

.root .table > * {
  width: calc(100% / 2 - 15px);
}

.root .table .item {
  border: 1px solid #DDDDDD;
  border-radius: 10px;
  padding: 15px 20px;
  margin-bottom: 15px;
  cursor: pointer;
}

.root .deleteButton {
  max-width: 300px;
  margin: auto;
  margin-top: 25px;
}

.root .deletePopup {
  text-align: center;
}

.root .deletePopup > p {
  margin-bottom: 25px;
}

.root .deletePopup > :last-child {
  margin-top: 15px;
}

@media (max-width: 640px) {
  .root > h1 {
    text-align: unset;
  }

  .root .table {
    flex-direction: column;
  }

  .root .table > * {
    width: 100%;
  }

  .root .table .item {
    border-left: 0;
    border-right: 0;
    border-top: 0;
    border-radius: 0;
    padding: 15px 0;
  }

  .root .deletePopup .deleteButton {
    max-width: unset;
  }
}

@media (min-width: 640px) {
  .root .table .item > p {
    font-size: 20px;
  }

  .root .deletePopup > p {
    font-size: 20px;
  }
}
