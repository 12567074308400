.root .header > :nth-child(1) {
  margin-bottom: 30px;
}

.root .table {
  padding: 50px 0 25px 0;
}

.root .table > * {
  margin-bottom: 25px;
}

@media (min-width: 640px) {
  .root .header {
    text-align: center;
    max-width: 60%;
    margin: auto;
    font-size: 20px;
  }

  .root .table {
    display: flex;
    justify-content: space-between;
  }

  .root .table > * {
    width: calc(100% / 3 - 15px);
  }

  .root .button {
    max-width: 300px;
    margin: auto;
  }
}
