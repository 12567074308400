.root {
  background: #201574;
  color: #fff;
  margin-top: 70px;
  padding: 30px 20px;
}

.root > * {
  padding: 0;
}

.root .white {
  color: #fff;
}

.link:link,
.link:visited,
.link:active,
.link:hover {
  color: #fff;
}

.root .top,
.root .bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.root .top .logo svg {
  max-width: 30px;
  max-height: 30px;
}

.root .top .links > * {
  display: block;
}

.root .top .links h5 {
  line-height: initial;
  margin: 40px 0 15px 0;
}

.root .top .social svg {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

.root .top .social svg path {
  fill: #fff;
}

.root .top .newsletter p {
  margin-bottom: 20px;
}

.root .bottom {
  margin-top: 40px;
}

.root .bottom span {
  font-family: 'Lexend Deca', sans-serif;
}

.root .bottom .copyright > * {
  display: block;
}

@media (min-width: 640px) {
  .root {
    padding: 55px 30px 40px 30px;
  }
  
  .root .top .logo svg {
    max-width: 38px;
    max-height: 38px;
  }

  .root .top .links h5 {
    line-height: initial;
    margin: 0 0 15px 0;
    font-size: 17px;
  }

  .root .top .social {
    margin-top: 25px;
  }

  .root .top,
  .root .bottom {
    flex-direction: row;
  }

  .root .top > *,
  .root .bottom > * {
    width: calc(100% / 5);
  }

  .root .top > :last-child {
    width: calc(100% / 5 * 2);
  }

  .root .bottom > :nth-child(2) {
    flex-grow: 1;
  }

  .root .bottom > :last-child {
    text-align: end;
  }

  .root .bottom .copyright > * {
    display: inline;
    margin-right: 30px;
  }
}
