/* Hidden class utilities for responsive */
.hiddenSmall {
  display: none;
}

.hiddenMedium {
  display: block;
}

.hiddenLarge {
  display: block;
}

@media (min-width: 640px) {
  .hiddenSmall {
    display: block !important;
  }

  .hiddenMedium {
    display: none !important;
  }
}

@media (min-width: 1024px) {
  .hiddenMedium {
    display: block !important;
  }

  .hiddenLarge {
    display: none !important;
  }
}

/* Visible class utilities for responsive */
.visibleSmall {
  display: block;
}

.visibleMedium {
  display: none;
}

.visibleLarge {
  display: none;
}

@media (min-width: 640px) {
  .visibleSmall {
    display: none !important;
  }

  .visibleMedium {
    display: block !important;
  }
}

@media (min-width: 1024px) {
  .visibleMedium {
    display: none !important;
  }

  .visibleLarge {
    display: block !important;
  }
}
