.textCenter {
    text-align: center;
}

.root .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.root .container {
    display: grid;
    gap: 20px;
}

.root .container .scans > * {
    margin-bottom: 10px;
}

.root .container .scan {
    border: 1px solid #999999;
    border-radius: 10px;
    padding: 5px;
}

.root .container .scan.underline {
    text-decoration: underline;
}

.root .container .scan.active {
    background: #5E7EFF;
    color: #fff;
}

.root .container .results .tabs {
    margin-bottom: 10px;
}

.root .container .results .image {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.root .container .results .image img {
    max-width: 300px;
    max-height: 400px;
}

.root .container .results .image p {
    max-width: 300px;
}

.root .container .results .annotationButton {
    margin-top: 25px;
}

.root .pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

@media (min-width: 640px) { 
    .root .container {
        grid-template-columns: 1fr 1fr;
    }  
}