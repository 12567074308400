/** Global styles for images **/
.root img,
.root svg {
  max-width: 100%;
}

div[class*="Button_button"].link {
  text-decoration: underline;
  font-size: 15px;
}

.lightBlue {
  color: #585097;
}

/** Header **/
.root .headerButton {
  padding: 6.5px 0px;
  background: #5E7EFF;
  text-align: center;
  position: fixed;
  top: 70px;
  width: 100%;
  z-index: 3;
}

.root .headerButton .button {
  border: 1px solid #fff;
  padding: 5px 30px;
}

.root .navbarMobile {
  border-bottom: 0;
}

/** Cover **/
.cover {
  padding-top: 110px;
  overflow: hidden;
}

.cover > .videoBackground {
  position: relative;
  overflow: hidden;
}

.cover > .videoBackground > video {
  width: 100%;
}

.cover > .videoBackground > .overlay {
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}

.cover .content {
  padding: 40px 25px 0 25px;
}

.cover .content > :nth-child(1) {
  margin-bottom: 25px;
}

.cover .content > :nth-child(2) {
  margin-bottom: 15px;
}

/* Not using Visibility to hide because does not work well with top/left position */
.cover > .videoBackground .playButton {
  display: none;
}

@media (max-width: 640px) {
  .cover > .videoBackground > .overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 98%;
  }

  .cover > .videoBackground .playButton {
    z-index: 2;
    display: block;
  }

  .cover .content .button {
    background: #d94b2f;
    color: #fff;
    border: solid 1.5px #d94b2f;
    display: block;
  }
}

@media (min-width: 640px) {
  .cover {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100vh;
    color: #fff;
    background-color: gray;
    text-align: center;
    padding: 0 25px;
    position: relative;
  }

  .cover > .videoBackground {
    min-height: initial;
    position: initial;
    overflow: initial;
  }

  .cover > .videoBackground > video {
    /* Custom */
    width: 100vw;
    min-width: 177.77vh; /* Given a 16:9 aspect ratio, 16/9*100 = 177.77 */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 56.25vw; /* Given a 16:9 aspect ratio, 9/16*100 = 56.25 */
    min-height: 100vh;
  }

  .cover > .content {
    margin-top: 58px;
    padding: 0;
    max-width: 900px;
    z-index: 2;
  }

  .cover > h1 {
    max-width: 700px;
  }

  .cover .content > :nth-child(3) {
    margin-bottom: 50px;
  }

  .cover .content .button {
    border: solid 1.5px #fff;
  }

  .cover .content .button:hover {
    border: solid 1.5px #201574;
  }
}

/** Sections **/
.root > :nth-child(4) {
  padding-top: 0;
}

.sections .section {
  padding-top: 70px; /* 70px on top is what gives space to the header when clicking in anchors */
  overflow: hidden; /* To allow to set images outside div and scroll does not appear */
}

.sections > .section:first-child {
  padding-top: 0;
}

/** Welcome section 0 **/
.sections div[id="welcome"] .welcomeSection0 {
  padding: 70px 25px 0 25px;
}

.sections div[id="welcome"] .welcomeSection0 .header {
  padding-bottom: 50px;
}

.sections div[id="welcome"] .welcomeSection0 .header > :nth-child(1) {
  margin-bottom: 25px;
}

.sections div[id="welcome"] .welcomeSection0 .cards > :nth-child(1) {
  margin-bottom: 25px;
}

.sections div[id="welcome"] .welcomeSection0 .cards .card {
  background-size: cover;
  background-position: center;
  height: 225px;
  border-radius: 20px;
}

.sections div[id="welcome"] .welcomeSection0 .cards .card .container {
  display: flex;
  justify-content: space-between;
  padding: 30px;
}

.sections div[id="welcome"] .welcomeSection0 .cards .card .container .button .buttonContainer {
  display: flex;
  align-items: center;
}

.sections div[id="welcome"] .welcomeSection0 .cards .card .container .button .buttonContainer svg {
  width: 10px;
}

.sections div[id="welcome"] .welcomeSection0 .cards .card .container .button:hover svg polyline {
  stroke: #fff;
}

.sections div[id="welcome"] .welcomeSection0 .cards .card .container > h2 {
  font-size: 20px;
}

.sections div[id="welcome"] .welcomeSection0 .cards .card .container .button {
  padding: 6.5px 20px;
}

.sections div[id="welcome"] .welcomeSection0 .cards .card .container .button .buttonContainer {
  font-size: 12px;
}

.sections div[id="welcome"] .welcomeSection0 .cards .card .container .button svg {
  width: 15px;
  margin-left: 15px;
}

.sections div[id="welcome"] .welcomeSection0 .cards .card .container > :nth-child(1) {
  width: 50%;
}

@media (min-width: 640px) {
  .sections div[id="welcome"] .welcomeSection0 {
    padding: 70px 0 0 0;
  }

  .sections div[id="welcome"] .welcomeSection0 .header {
    text-align: center;
  }

  .sections div[id="welcome"] .welcomeSection0 .header > p {
    font-size: 20px;
  }

  .sections div[id="welcome"] .welcomeSection0 .cards {
    display: flex;
    justify-content: space-between;
    max-width: 1000px;
    margin: auto;
  }

  .sections div[id="welcome"] .welcomeSection0 .cards > :nth-child(1) {
    margin-bottom: 0;
  }

  .sections div[id="welcome"] .welcomeSection0 .cards .card {
    width: calc(100% / 2 - 10px);
    height: 350px;
  }

  .sections div[id="welcome"] .welcomeSection0 .cards .card .container > h2 {
    font-size: 28px;
    line-height: 31px;
  }

  .sections div[id="welcome"] .welcomeSection0 .cards .card .container .button {
    padding: 6.5px 30px;
  }

  .sections div[id="welcome"] .welcomeSection0 .cards .card .container .button .buttonContainer {
    font-size: 20px;
  }

  .sections div[id="welcome"] .welcomeSection0 .cards .card .container .button .buttonContainer svg {
    width: 12px;
  }
}

/** Welcome section 1 **/
.sections div[id="welcome"] .welcomeSection1 {
  color: #fff;
  padding-top: 70px;
}

.sections div[id="welcome"] .welcomeSection1 > :nth-child(1) {
  background-color: #201574;
  padding: 70px 25px;
}

.sections div[id="welcome"] .welcomeSection1 > :nth-child(1) .content {
  margin-bottom: 50px;
}

.sections div[id="welcome"] .welcomeSection1 > :nth-child(1) .content > * {
  margin-bottom: 50px;
}

.sections div[id="welcome"] .welcomeSection1 > :nth-child(1) .content > :last-child {
  margin-bottom: 0;
}

.sections div[id="welcome"] .welcomeSection1 > :nth-child(1) .image {
  text-align: center;
}

.sections div[id="welcome"] .welcomeSection1 > :nth-child(1) .image img {
  height: 350px;
}

@media (min-width: 768px) {
  .sections div[id="welcome"] .welcomeSection1 > :nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sections div[id="welcome"] .welcomeSection1 > :nth-child(1) .content {
    max-width: 450px;
    margin-bottom: 0;
  }
}

@media (min-width: 1024px) {
  .sections div[id="welcome"] .welcomeSection1 {
    display: flex;
    height: 580px;
    padding: 70px 0 0 0;
  }

  .sections div[id="welcome"] .welcomeSection1 > :nth-child(1) .image img {
    position: absolute;
    height: 525px;
    bottom: 27px;
    right: -100px;
    margin-bottom: 0;
  }

  .sections div[id="welcome"] .welcomeSection1 > :nth-child(1) .content {
    padding-left: 150px;
  }

  .sections div[id="welcome"] .welcomeSection1 > :nth-child(1) {
    position: relative;
    width: 85%;
    border-radius: 20px;
  }

  .sections div[id="welcome"] .welcomeSection1 > :nth-child(2) {
    width: 15%;
  }
}

@media (min-width: 1200px) {
  .sections div[id="welcome"] .welcomeSection1 > :nth-child(1) .content {
    padding-left: 100px;
  }
}

/** Welcome section 3 **/
.sections div[id="welcome"] .welcomeSection3 {
  padding-top: 70px;
}

.sections div[id="welcome"] .welcomeSection3 > :nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  position: relative;
  background-color: #F6F1EE;
  padding: 0 25px;
  margin-top: 200px;
  height: 100%;
}

.sections div[id="welcome"] .welcomeSection3 > :nth-child(2) .content {
  padding-top: 150px;
}

.sections div[id="welcome"] .welcomeSection3 > :nth-child(2) .content > * {
  margin-bottom: 25px;
}

.sections div[id="welcome"] .welcomeSection3 > :nth-child(2) .content > :last-child {
  margin-bottom: 70px;
}

.sections div[id="welcome"] .welcomeSection3 > :nth-child(2) img {
  z-index: 2;
  height: 350px;
  position: absolute;
  top: -225px;
}

.sections div[id="welcome"] .welcomeSection3 > :nth-child(2) svg {
  z-index: 1;
  width: 100%;
  position: absolute;
  top: -300px;
}

@media (min-width: 768px) {
  .sections div[id="welcome"] .welcomeSection3 {
    height: 450px;
  }

  .sections div[id="welcome"] .welcomeSection3 > :nth-child(2) {
    flex-direction: row;
    margin-top: 0;
  }

  .sections div[id="welcome"] .welcomeSection3 > :nth-child(2) .content {
    width: 470px;
    padding-top: 0;
    padding-right: 50px;
    margin-bottom: 0;
  }

  .sections div[id="welcome"] .welcomeSection3 > :nth-child(2) .content > :last-child {
    margin-bottom: 0;
  }

  .sections div[id="welcome"] .welcomeSection3 > :nth-child(2) img {
    height: 320px;
    top: 65px;
    left: 50px;
  }

  .sections div[id="welcome"] .welcomeSection3 > :nth-child(2) svg {
    height: 360px;
    top: 0;
    left: -350px;
  }
}

@media (min-width: 1024px) {
  .sections div[id="welcome"] .welcomeSection3 {
    display: flex;
    height: 630px;
  }

  .sections div[id="welcome"] .welcomeSection3 > :nth-child(2) .content {
    padding-right: 100px;
  }

  .sections div[id="welcome"] .welcomeSection3 > :nth-child(2) img {
    height: 550px;
    top: 40px;
    left: -125px;
  }

  .sections div[id="welcome"] .welcomeSection3 > :nth-child(2) svg {
    height: 450px;
    top: 90px;
    left: -500px;
  }

  .sections div[id="welcome"] .welcomeSection3 > :nth-child(1) {
    width: 15%;
  }

  .sections div[id="welcome"] .welcomeSection3 > :nth-child(2) {
    width: 85%;
    border-radius: 20px;
  }
}

/** How section 1 **/
.sections div[id="how"] .howSection1 {
  width: 100%;
  text-align: center;
}

/** How section 2 **/
.sections div[id="how"] .howSection2 {
  padding: 40px 25px 0 25px;
  max-width: 450px;
  margin: 0 auto;
}

.sections div[id="how"] .howSection2 .image img {
  width: 100%;
  max-height: 400px;
  border-radius: 20px;
}

.sections div[id="how"] .howSection2 .content {
  padding-top: 50px;
}

.sections div[id="how"] .howSection2 .content > * {
  width: 100%;
}

.sections div[id="how"] .howSection2 .content .text {
  display: flex;
}

.sections div[id="how"] .howSection2 .content .text >:nth-child(2) {
  margin-left: 25px;
}

.sections div[id="how"] .howSection2 .content span.numbered {
  border: 1px solid #FF6C88;
  border-radius: 50%;
  padding: 14px 20px;
}

@media (min-width: 1024px) {
  .sections div[id="how"] .howSection2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 400px;
    max-width: 100%;
    margin: 0;
  }

  .sections div[id="how"] .howSection2 .content {
    padding-top: 0;
  }

  .sections div[id="how"] .howSection2 > * {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% / 2 - 25px);
  }

  .sections div[id="how"] .howSection2 .image {
    align-items: center;
  }

  .sections div[id="how"] .howSection2 .content .text {
    max-width: 400px;
  }
}

/** How section 3 **/
.sections div[id="how"] .howSection3 {
  padding: 25px 25px 0 25px;
  max-width: 450px;
  margin: 0 auto;
}

.sections div[id="how"] .howSection3 .image img {
  width: 100%;
  max-height: 400px;
  border-radius: 20px;
}


.sections div[id="how"] .howSection3 .content {
  padding-bottom: 50px;
}

.sections div[id="how"] .howSection3 .content .text {
  display: flex;
}

.sections div[id="how"] .howSection3 .content .text > :nth-child(2) {
  margin-left: 25px;
}

.sections div[id="how"] .howSection3 .content span.numbered {
  border: 1px solid #FF6C88;
  border-radius: 50%;
  padding: 13px 20px;
}

@media (min-width: 1024px) {
  .sections div[id="how"] .howSection3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 400px;
    max-width: 100%;
    margin: 0;
    padding-top: 70px;
  }

  .sections div[id="how"] .howSection3 .content {
    padding-bottom: 0;
  }

  .sections div[id="how"] .howSection3 > * {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% / 2 - 25px);
  }

  .sections div[id="how"] .howSection3 .image {
    align-items: center;
  }

  .sections div[id="how"] .howSection3 .content .text {
    max-width: 450px;
  }
}

/** How section 4 **/
.sections div[id="how"] .howSection4 {
  padding: 70px 0 0 0;
}

.sections div[id="how"] .howSection4 > :nth-child(1) {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #F6F1EE;
  padding: 70px 25px;
}

.sections div[id="how"] .howSection4 .content > * {
  margin-bottom: 30px;
}

.sections div[id="how"] .howSection4 .content .text {
  display: flex;
}

.sections div[id="how"] .howSection4 .content .text > :nth-child(2) {
  margin-left: 25px;
}

.sections div[id="how"] .howSection4 > :nth-child(1) .containerImage1 {
  text-align: center;
}

.sections div[id="how"] .howSection4 > :nth-child(1) .image1,
.sections div[id="how"] .howSection4 > :nth-child(1) .image2 {
  z-index: 2;
  height: 350px;
}

.sections div[id="how"] .howSection4 > :nth-child(1) .image1 {
  margin-bottom: 25px;
}

.sections div[id="how"] .howSection4 > :nth-child(1) span.numbered {
  border: 1px solid #FF6C88;
  border-radius: 50%;
  padding: 13px 20px;
}

@media (max-width: 767px) {
  .sections div[id="how"] .howSection4 > :nth-child(1) .image4 {
    display: none; /* TODO: Change Visibility to allow arrays of accepted values **/
  }
}

@media (min-width: 768px) {
  .sections div[id="how"] .howSection4 > :nth-child(1) {
    position: relative;
    margin-top: 200px;
  }

  .sections div[id="how"] .howSection4 > :nth-child(1) .content {
    max-width: 500px;
    padding-top: 200px;
  }

  .sections div[id="how"] .howSection4 > :nth-child(1) .content > :last-child {
    margin-bottom: 0;
  }

  .sections div[id="how"] .howSection4 > :nth-child(1) .image1 {
    position: absolute;
    height: 350px;
    top: -150px;
    left: -250px;
    right: 0;
    margin: 0 auto;
  }

  .sections div[id="how"] .howSection4 > :nth-child(1) .image2 {
    position: absolute;
    height: 350px;
    top: -150px;
    left: 250px;
    right: 0;
    margin: 0 auto;
  }

  .sections div[id="how"] .howSection4 > :nth-child(1) .image4 {
    position: absolute;
    width: 550px;
    top: -250px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
}

@media (min-width: 1024px) {
  .sections div[id="how"] .howSection4 {
    display: flex;
    height: 630px;
  }

  .sections div[id="how"] .howSection4 > :nth-child(1) {
    flex-direction: row;
    margin-top: 0;
    border-radius: 20px;
  }

  .sections div[id="how"] .howSection4 > :nth-child(1) .content {
    padding: 0 0 0 25px;
    max-width: 350px;
  }

  .sections div[id="how"] .howSection4 > :nth-child(1) .image1 {
    height: 550px;
    top: 40px;
    left: initial;
    right: 125px;
    margin: 0;
  }

  .sections div[id="how"] .howSection4 > :nth-child(1) .image2 {
    height: 550px;
    top: 40px;
    left: initial;
    right: -175px;
    margin: 0;
  }

  .sections div[id="how"] .howSection4 > :nth-child(1) .image3 {
    position: absolute;
    bottom: -70px;
    left: 75px;
  }

  .sections div[id="how"] .howSection4 > :nth-child(1) .image3 svg {
    max-width: 140px;
    max-height: 140px;
  }

  .sections div[id="how"] .howSection4 > :nth-child(1) .image4 {
    height: 375px;
    top: 125px;
    left: initial;
    right: -250px;
    margin: 0;
  }

  .sections div[id="how"] .howSection4 > :nth-child(1) {
    width: 80%;
  }

  .sections div[id="how"] .howSection4 > :nth-child(2) {
    width: 20%;
  }
}

@media (min-width: 1200px) {
  .sections div[id="how"] .howSection4 > :nth-child(1) .content {
    padding: 0 0 0 100px;
  }
}

/** How section 5 **/
.sections div[id="how"] .howSection5 {
  color: #fff;
}

.sections div[id="how"] .howSection5 > :nth-child(2) {
  background-color: #201574;
  padding: 70px 25px;
}

.sections div[id="how"] .howSection5 > :nth-child(2) .content {
  padding-top: 25px;
}

.sections div[id="how"] .howSection5 > :nth-child(2) .content > * {
  margin-bottom: 25px;
}

.sections div[id="how"] .howSection5 > :nth-child(2) .content > :last-child {
  margin-bottom: 0;
}

.sections div[id="how"] .howSection5 > :nth-child(2) img {
  border-radius: 20px;
}

.sections div[id="how"] .howSection5 > :nth-child(2) .content img {
  width: 100%;
}

@media (min-width: 768px) {
  .sections div[id="how"] .howSection5 > :nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sections div[id="how"] .howSection5 > :nth-child(2) > * {
    width: calc(100% / 2 - 25px);
  }

  .sections div[id="how"] .howSection5 > :nth-child(2) .content {
    max-width: 350px;
    padding-top: 0;
  }
}

@media (min-width: 1024px) {
  .sections div[id="how"] .howSection5 {
    display: flex;
    height: 630px;
    padding: 70px 0 0 0;
  }

  .sections div[id="how"] .howSection5 > :nth-child(2) img {
    position: absolute;
    height: 325px;
    bottom: 150px;
    left: -150px;
    margin-bottom: 0;
  }

  .sections div[id="how"] .howSection5 > :nth-child(2) .content {
    padding-right: 50px;
  }

  .sections div[id="how"] .howSection5 > :nth-child(1) {
    width: 20%;
  }

  .sections div[id="how"] .howSection5 > :nth-child(2) {
    position: relative;
    width: 80%;
    border-radius: 20px;
  }
}

@media (min-width: 1200px) {
  .sections div[id="how"] .howSection5 > :nth-child(2) .content {
    padding-right: 100px;
  }
}

/** Price section 1 **/
/* .sections div[id="price"] .priceSection1 {
  text-align: left;
  color: #201574;
  padding: 0 25px 60px 25px;
}

@media (min-width: 640px) {
  .sections div[id="price"] .priceSection1 {
    text-align: center;
  }
} */

/** Price section 2 **/
/* .sections div[id="price"] .priceSection2 {
  max-width: 650px;
  margin: 0 auto;
  background-color: #F6F1EE;
  text-align: left;
  padding: 70px 25px;
  position: relative;
}

.sections div[id="price"] .priceSection2 > :nth-child(1) {
  align-items: center;
  margin-bottom: 20px;
}

.sections div[id="price"] .priceSection2 > :nth-child(1) .content > svg {
  position: absolute;
  top: -55px;
  right: 0;
}

.sections div[id="price"] .priceSection2 > :nth-child(1) .content .text > *,
.sections div[id="price"] .priceSection2 > :nth-child(2) > * {
  margin-bottom: 25px;
}

.sections div[id="price"] .priceSection2 > :nth-child(1) .content .text > :last-child,
.sections div[id="price"] .priceSection2 > :nth-child(2) > :last-child {
  margin-bottom: 0;
}

.sections div[id="price"] .priceSection2 > :nth-child(1) .content .text > h1 {
  font-size: 60px;
  color: #201574;
}

.sections div[id="price"] .priceSection2 > :nth-child(1) .content .text > h2 {
  margin-bottom: 10px;
}

.sections div[id="price"] .priceSection2 > :nth-child(1) .content .text > h2.lineThrough {
  color: #D94B2F;
  text-decoration: line-through;
  margin-bottom: 25px;
}

.sections div[id="price"] .priceSection2 > :nth-child(1) .content .text > h2 > span {
  color: #000;
}

.sections div[id="price"] .priceSection2 > :nth-child(2) div[class*="Button_red"] {
  display: block;
}

@media (max-width: 767px) {
  .sections div[id="price"] .priceSection2 > :nth-child(1) .image svg {
    display: none;
  }
}

@media (min-width: 768px) {
  .sections div[id="price"] .priceSection2 {
    text-align: center;
  }

  .sections div[id="price"] .priceSection2 > :nth-child(1) {
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
  }

  .sections div[id="price"] .priceSection2 > :nth-child(1) .content {
    text-align: left;
  }

  .sections div[id="price"] .priceSection2 > :nth-child(1) .image svg {
    width: 280px;
    height: 280px;
  }

  .sections div[id="price"] .priceSection2 > :nth-child(1) > * {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
  }

  .sections div[id="price"] .priceSection2 > :nth-child(1) .image {
    align-items: center;
  }

  .sections div[id="price"] .priceSection2 > :nth-child(1) .content .text {
    max-width: 400px;
  }

  .sections div[id="price"] .priceSection2 > :nth-child(2) div[class*="Button_red"] {
    display: inline-block;
  }
}

@media (min-width: 1024px) {
  .sections div[id="price"] .priceSection2 {
    max-width: 950px;
  }
} */

/** Price section 3 **/
/* .sections div[id="price"] .priceSection3 {
  padding: 70px 25px 0 25px;
}

.sections div[id="price"] .priceSection3 .content .text > p:nth-of-type(1),
.sections div[id="price"] .priceSection3 .content .text > p:nth-last-of-type(1),
.sections div[id="price"] .priceSection3 .content .text > p:nth-last-of-type(2) {
  margin-bottom: 25px;
}

.sections div[id="price"] .priceSection3 .content p.blue {
  color: #201574;
}

.sections div[id="price"] .priceSection3 .content .text div[class*="Button_blue"] {
  display: block;
}

@media (max-width: 767px) {
  .sections div[id="price"] .priceSection3 .image svg {
    display: none;
  }
}

@media (min-width: 768px) {
  .sections div[id="price"] .priceSection3 {
    display: flex;
    align-items: center;
  }

  .sections div[id="price"] .priceSection3 .image svg {
    height: 250px;
  }

  .sections div[id="price"] .priceSection3 > * {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
  }

  .sections div[id="price"] .priceSection3 .image {
    align-items: center;
    text-align: right;
    margin-right: 50px;
  }

  .sections div[id="price"] .priceSection3 .content .text {
    max-width: 400px;
  }

  .sections div[id="price"] .priceSection3 .content .text div[class*="Button_blue"] {
    display: inline-block;
  }
} */

/** Testimonials section 1 **/
.sections div[id="testimonials"] {
  padding-top: 0;
}

.sections div[id="testimonials"] .testimonialsSection1 > :nth-child(1) {
  background-color: #F6F1EE;
  padding: 70px 25px;
}

.sections div[id="testimonials"] .testimonialsSection1 > :nth-child(1) > * {
  width: 100%; /* Fix for IE */
}

@media (min-width: 1024px) {
  .sections div[id="testimonials"] {
    padding-top: 70px;
  }

  .sections div[id="testimonials"] .testimonialsSection1 {
    display: flex;
  }

  .sections div[id="testimonials"] .testimonialsSection1 > :nth-child(1) {
    display: flex;
    align-items: center;
    padding: 70px 30px;
    width: 100%;
    border-radius: 20px;
  }

  .sections div[id="testimonials"] .testimonialsSection1 > :nth-child(2) {
    width: 0%;
  }
}

/** Testimonials section 2 **/
/* .sections div[id="testimonials"] .testimonialsSection2 {
  max-width: 800px;
  margin: 0 auto;
  padding: 70px 25px 0 25px;
} */

/** Subscribe section 2 **/
/* .sections div[id="subscribe"] .subscribeSection2 {
  padding: 0 25px;
}

@media (min-width: 640px) {
  .sections div[id="subscribe"] .subscribeSection2 {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }
} */

/** Subscribe section 3 **/
/* .sections div[id="subscribe"] .subscribeSection3 {
  padding: 40px 25px;
  padding-bottom: 0;
}

.sections div[id="subscribe"] .subscribeSection3 .image img {
  width: 100%;
}

.sections div[id="subscribe"] .subscribeSection3 .content > * {
  margin-bottom: 25px;
}

@media (min-width: 640px) {
  .sections div[id="subscribe"] .subscribeSection3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .sections div[id="subscribe"] .subscribeSection3 .content {
    padding-top: 0;
  }

  .sections div[id="subscribe"] .subscribeSection3 > * {
    width: calc(100% / 2 - 25px);
  }

  .sections div[id="subscribe"] .subscribeSection3 .content {
    max-width: 400px;
  }

  .sections div[id="subscribe"] .subscribeSection3 .content > :last-child {
    margin-bottom: 0;
  }
} */
