.modal {
  padding: 40px 20px 40px 20px;
}

.modal > .header {
  text-align: center;
}

.modal > .content {
  padding: 30px 0 0 0;
}

.modal > .actions {
  text-align: center;
}

.modal > .actions > * {
  margin-bottom: 25px;
}

.modal > .actions div[class*="Button_"] {
  display: block;
}

.modal .close {
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;
  border: 0;
  background: #fff;
}

@media (min-width: 640px) {
  .modal {
    padding: 40px 75px 40px 75px;
  }

  .modal > .actions {
    display: flex;
    justify-content: space-between;
  }

  .modal > .actions > * {
    margin-bottom: 0;
  }

  .modal > .actions div[class*="Button_"] {
    display: inline-block;
  }
}
