.lightBlue {
  color: #585097;
}

.root .subTotal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
}

.root .summary {
  display: flex;
  justify-content: space-between;
  font-size: 20px;
}

.root .summary > * {
  width: calc(100% / 3 - 15px);
}

.root .summary .title {
  display: flex;
  align-items: center;
}

.root .summary .title > :nth-child(2) {
  margin-left: 40px;
  cursor: pointer;
}

.root .subTotal > :nth-child(1) {
  display: flex;
  align-items: center;
}

.root .subTotal > :nth-child(1) > :nth-child(1) {
  margin-right: 15px;
}

.root .couponError {
  margin-bottom: 15px;
}

.root .couponError,
.root .coupon {
  color: #FF6C88;
}

.root .coupon .couponInput {
  display: flex;
}

.root .coupon .couponInput .input input {
  border: 1px solid #FF6C88;
  border-radius: 4px 0 0 4px;
}

.root .coupon .couponInput .button {
  background: #FF6C88;
  border: 1px solid #FF6C88;
  color: #fff;
  font-size: 16px;
  padding: 19px 30px;
  border-radius: 0 4px 4px 0;
}

.root .coupon .couponButton {
  background: #FF6C88;
  border: 1px solid #FF6C88;
  color: #fff;
}

.root .coupon .couponButton .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.root .coupon .couponButton .container > svg {
  margin-left: 20px;
  width: 25px;
  height: 25px;
}

.root .coupon .couponButton .container > svg g {
  stroke: #fff;
}

.root .buttons {
  display: flex;
  justify-content: space-between;
}

.root .buttons > :nth-child(2) {
  width: 30%;
  margin-left: 20px;
}

@media (max-width: 640px) {
  .root .summary {
    flex-direction: column;
    font-size: 16px;
  }

  .root .summary > * {
    width: 100%;
    margin-top: 10px;
  }

  .root .summary .title {
    justify-content: space-between;
  }

  .root .coupon .couponInput .button {
    padding: 9px 30px;
  }

  .root .buttons {
    flex-direction: column;
  }

  .root .buttons > :nth-child(2) {
    width: 100%;
    margin-left: 0;
    margin-top: 20px;
  }
}
