.textCenter {
    text-align: center;
}

.root .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.root .container {
    display: grid;
    gap: 20px;
}

.root .container .editor .actions {
    display: grid;
    gap: 5px;
    margin-bottom: 20px;
}

.root .container .editor .actions > * {
    border: 1px solid #999999;
    border-radius: 10px;
    padding: 5px;
    text-align: center;
}

.root .container .editor .actions > :last-child {
    background: #5E7EFF;
}

.root .container .editor .actions > :last-child span {
    color: #fff;
}


.root .container .editor .arrows {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.root .container .editor .arrows .image {
    width: 100%;
    height: 500px;
}

.root .container .editor .arrows .image > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.root .container .editor .arrows .left,
.root .container .editor .arrows .right {
    position: absolute;
    font-weight: bold;
    font-size: 25px;
}

.root .container .editor .arrows .left {
    left: 0;

}

.root .container .editor .arrows .right {
    right: 0;
}

.root .container .form .textarea {
    margin-top: 20px;
}

.root .container .form .button {
    margin-top: 20px;
}

@media (min-width: 640px) { 
    .root .container {
        grid-template-columns: 1fr 1fr;
    }

    .root .container .editor .actions {
        grid-template-columns: repeat(4, 1fr);
    }
}