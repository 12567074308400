.root {
  padding: 70px 0 0 0;
}

.fixedWidth {
  padding: 70px 30px 0 30px;
}

.fullWidth {
  padding: 70px 0 0 0;
}

.fixedWidth,
.fullWidth {
  max-width: 100%;
}

@media (min-width: 640px) {
  .fixedWidth {
    max-width: calc(100% / 5 * 4);
  }

  .fullWidth {
    max-width: 1200px;
  }

  .fixedWidth,
  .fullWidth {
    padding: 90px 0 0 0;
    margin: 0 auto;
  }
}