.root {
  margin-top: 40px;
}

.root .section {
  padding: 40px 0;
}

.root .section:last-child {
  padding-bottom: 0;
}

.root .section > p {
  margin-bottom: 25px;
}

.root .section > p:last-child {
  margin-bottom: 0;
}

@media (min-width: 640px) {
  .root > h1 {
    text-align: center;
  }
}
