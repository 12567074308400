.root .header {
  padding-top: 20px;
}

.root .step4 {
  text-align: center;
  font-size: 16px;
}

.root .step4 > * {
  margin-bottom: 20px;
}

@media (min-width: 640px) {
  .root .step4 {
    font-size: 20px;
  }

  .root .button {
    max-width: 300px;
    margin: auto;
  }
}