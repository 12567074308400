.root {
  padding: 40px 25px 0 25px;
}

.root > :last-child {
  margin-top: 50px;
}

.root .halfWidth > *,
.root .marginBottom {
  margin-bottom: 25px;
}

.root .buttons {
  text-align: center;
}

@media (min-width: 640px) {
  .root {
    padding: 40px 0 0 0;
  }

  .root > h1 {
    text-align: center;
  }

  .root .halfWidth {
    display: flex;
    justify-content: space-between;
  }

  .root .halfWidth > * {
    width: calc(100% / 2 - 15px);
  }
}
