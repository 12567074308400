.root {
  color: #BD0000;
}

.root {
  display: flex;
  align-items: center;
}

.root > img {
  margin-right: 5px;
  width: 17px;
  height: 17px;
}

.root > svg path {
  fill: #BD0000;
}
