.root {
  display: table;
  width: 100%;
  table-layout: fixed;
  position: relative;
}

.root .step {
  display: table-cell;
  text-align: center;
  vertical-align: top;
  overflow: visible;
  position: relative;
  padding-top: 20px;
  font-size: 18px;
  color: #DDDDDD;
}

.root .step.completed > .node,
.root .step.completed > h5 {
  cursor: pointer;
}

.root .step.completed,
.root .step.inProgress {
  color: #201574;
}

.root .step:not(:last-child):before {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  top: 6px;
  background: #DDDDDD;
  height: 1px;
  width: 100%;
}

.root .step .node {
  display: inline-block;
  background: #DDDDDD;
  border-radius: 12px;
  height: 12px;
  width: 12px;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -8px;
}

.root .step.completed:before {
  background: #201574;
}

.root .step.completed .node {
  background: #201574;
}

.root .step.inProgress .node {
  background: #00C5E0;
}
