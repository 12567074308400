.root > *,
.root .buttonContainer > select {
  width: 100%;
}

.root.error {
  color: #BD0000;
}

.root .buttonContainer {
  position: relative;
}

.root .buttonContainer svg {
  width: 22px;
  top: 25px;
  right: 30px;
  position: absolute;
}

/** Fix for wrong width using 100% and padding **/
.root .buttonContainer > select {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
}

.root .label {
  margin-bottom: 5px;
}

.root.error .errorText {
  margin-top: 5px;
}

.root select,
.root select::placeholder {
  color: #201574;
  font-size: 16px;
}

.root.error select,
.root.error select::placeholder {
  color: #BD0000;
}

.root select {
  padding: 0 15px;
  border: 1px solid #201574;
  border-radius: 4px;
  height: 60px; /** Fix for Safari **/
}

.root select:focus {
  border: 1px solid #201574;
  outline: none;
}

.root.error select,
.root.error select:focus {
  border: 1px solid #BD0000;
}

.root .buttonContainer {
  position: relative;
}

@media (max-width: 640px) {
  .root .label {
    font-size: 12px;
  }

  .root select,
  .root select::placeholder {
    font-size: 12px;
    height: 40px; /** Fix for Safari **/
  }

  .root .buttonContainer svg {
    width: 11px;
    top: 15px;
    right: 20px;
  }
}
