.root {
  padding: 70px 25px 0 25px;
}

.root > h1 {
  margin-bottom: 25px;
}

.lightBlue {
  color: #585097;
}

.link:link,
.link:visited,
.link:active,
.link:hover {
  color: #585097;
}

@media (min-width: 640px) {
  .root {
    text-align: center;
  }
}
